<template>
  <div>
    <v-row>
      <v-col>
        <Heading title="Установки" />
      </v-col>
      <v-col
        align="right"
        align-self="center"
      >
        <v-btn
          color="blue"
          outlined
          @click="onAddClick"
        >
          Добавить
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="devices"
      class="elevation-1"
      :footer-props="{ itemsPerPageOptions: [5, 10, 20, 50] }"
      :items-per-page="20"
      :loading="!isLoaded"
    >
      <template #[`item.region`]="{ item }">
        {{ item.region ? item.region.name : '-' }}
      </template>

      <template #[`item.type`]="{ item }">
        {{ item.type ? item.type.name : '-' }}
      </template>

      <template #[`item.verified_at`]="{ item }">
        {{ item.verified_at | date }}
      </template>

      <template #[`item.next_verification`]="{ item }">
        {{ item.next_verification | date }}
      </template>

      <template #[`item.actions`]="{ item }">
        <v-btn text small @click="onEditClick(item.id)">
          Редактировать
        </v-btn>

        <v-btn text small @click="onDeleteClick(item.id)">
          Удалить
        </v-btn>
      </template>
    </v-data-table>

    <DeleteConfirmation
      :visible="deleteConfirmationVisible"
      @confirm="deleteConfirm"
      @decline="deleteDecline"
    />

    <DeviceModal
      :device="modalItem"
      @close="onModalClose"
    />
  </div>
</template>

<script>
import { DevicesService } from '@/common/api.service'
import DeleteConfirmation from "@/components/Modals/DeleteConfirmation"
import DeviceModal from "@/views/Manager/DeviceModal"

export default {
  components: {
    DeleteConfirmation,
    DeviceModal
  },

  data () {
    return {
      devices: [],
      isLoaded: false,
      toEditId: null,
      toDeleteId: null,
      deleteConfirmationVisible: false,

      headers: [
        {
          text: 'Регион',
          value: 'region',
          align: 'left',
          sortable: false
        },
        {
          text: 'Название',
          value: 'name',
          sortable: false
        },
        {
          text: 'Тип',
          value: 'type',
          sortable: false
        },
        {
          text: 'Номер',
          value: 'number',
          sortable: false
        },
        {
          text: 'Дата поверки',
          value: 'verified_at',
          sortable: false
        },
        {
          text: 'Следующая поверка',
          value: 'next_verification',
          sortable: false
        },
        {
          text: '',
          value: 'actions',
          align: 'right',
          sortable: false
        }
      ]
    }
  },

  methods: {
    onAddClick () {
      this.toEditId = 0
    },

    onEditClick (id) {
      this.toEditId = id
    },

    onDeleteClick (id) {
      this.toDeleteId = id
      this.deleteConfirmationVisible = true
    },

    deleteConfirm () {
      this.deleteConfirmationVisible = false
      DevicesService.delete(this.toDeleteId).then(() => this.fetchDevices())
    },

    deleteDecline () {
      this.deleteConfirmationVisible = false
    },

    onModalClose (needRefresh) {
      this.toEditId = null

      if (needRefresh) {
        this.devices = []
        this.isLoaded = false
        this.fetchDevices()
      }
    },

    fetchDevices () {
      DevicesService
        .get()
        .then(({ data }) => {
          this.isLoaded = true
          this.devices = data.devices
        })
    }
  },

  computed: {
    modalItem () {
      return this.toEditId !== 0 ? this.devices.find(device => device.id === this.toEditId) : {}
    }
  },

  beforeMount () {
    this.fetchDevices()
  }
}
</script>
