<template>
  <div>
    <v-row>
      <v-col>
        <Heading title="Установки"/>
      </v-col>
      <v-col align="right" align-self="center">
        <v-btn color="blue" outlined @click="onAddClick">Добавить</v-btn>
      </v-col>
    </v-row>

    <v-skeleton-loader v-if="!isLoaded" :loading="!isLoaded" type="table-tbody"></v-skeleton-loader>

    <v-card v-for="device in devices" :key="device.id" tile>
      <v-card-text class="d-flex">
        <v-row>
          <v-col sm="2">
            {{ device.region ? device.region.name : '-' }}
          </v-col>

          <v-col sm="2">
            {{ device.name }}
          </v-col>

          <v-col sm="1">
            {{ device.type ? device.type.name : undefined }}
          </v-col>

          <v-col sm="2">
            {{ device.number }}
          </v-col>

          <v-col sm="3">
            Дата поверки<br />{{ device.verified_at | date }}
          </v-col>

          <v-col sm="2">
            Дата следующей поверки<br />{{ device.next_verification | date }}
          </v-col>
        </v-row>

        <div class="buttons">
          <v-btn text small @click="onEditClick(device.id)">
            Редактировать
          </v-btn>

          <v-btn text small @click="onDeleteClick(device.id)">
            Удалить
          </v-btn>
        </div>
      </v-card-text>
    </v-card>

    <DeleteConfirmation
        :visible="deleteConfirmationVisible"
        @confirm="deleteConfirm"
        @decline="deleteDecline"
    />

    <DeviceModal
        :device="modalItem"
        @close="onModalClose"
    />
  </div>
</template>

<script>
import { DevicesService } from '@/common/api.service'
import DeleteConfirmation from "@/components/Modals/DeleteConfirmation"
import DeviceModal from "@/views/FilialManager/DeviceModal"

export default {
  components: {
    DeleteConfirmation,
    DeviceModal
  },
  data() {
    return {
      devices: [],
      isLoaded: false,
      toEditId: null,
      toDeleteId: null,
      deleteConfirmationVisible: false
    }
  },
  methods: {
    onAddClick() {
      this.toEditId = 0
    },

    onEditClick(id) {
      this.toEditId = id
    },

    onDeleteClick(id) {
      this.toDeleteId = id
      this.deleteConfirmationVisible = true
    },

    deleteConfirm() {
      this.deleteConfirmationVisible = false
      DevicesService.delete(this.toDeleteId).then(() => this.fetchDevices())
    },

    deleteDecline() {
      this.deleteConfirmationVisible = false
    },

    onModalClose(needRefresh) {
      this.toEditId = null

      if (needRefresh) {
        this.devices = []
        this.isLoaded = false
        this.fetchDevices()
      }
    },

    fetchDevices() {
      DevicesService
          .get(this.$store.state.auth.user.region_id)
          .then(({data}) => {
            this.isLoaded = true
            this.devices = data.devices
          })
    }
  },
  computed: {
    modalItem() {
      return this.toEditId !== 0 ? this.devices.find(device => device.id === this.toEditId) : {}
    }
  },
  beforeMount() {
    this.fetchDevices()
  }
}
</script>

<style scoped>
.buttons {
  display: flex;
  align-items: center;
}
</style>
