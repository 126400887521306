var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('Heading',{attrs:{"title":"Установки"}})],1),_c('v-col',{attrs:{"align":"right","align-self":"center"}},[_c('v-btn',{attrs:{"color":"blue","outlined":""},on:{"click":_vm.onAddClick}},[_vm._v(" Добавить ")])],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.devices,"footer-props":{ itemsPerPageOptions: [5, 10, 20, 50] },"items-per-page":20,"loading":!_vm.isLoaded},scopedSlots:_vm._u([{key:"item.region",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.region ? item.region.name : '-')+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.type ? item.type.name : '-')+" ")]}},{key:"item.verified_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.verified_at))+" ")]}},{key:"item.next_verification",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.next_verification))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){return _vm.onEditClick(item.id)}}},[_vm._v(" Редактировать ")]),_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){return _vm.onDeleteClick(item.id)}}},[_vm._v(" Удалить ")])]}}],null,true)}),_c('DeleteConfirmation',{attrs:{"visible":_vm.deleteConfirmationVisible},on:{"confirm":_vm.deleteConfirm,"decline":_vm.deleteDecline}}),_c('DeviceModal',{attrs:{"device":_vm.modalItem},on:{"close":_vm.onModalClose}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }