<template>
  <v-dialog
    v-model="isOpened"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-container>
        <v-form @submit.prevent="onSubmit">
          <v-card-text>
            <v-select
              :items="regions.map(item => ({ value: item.id, text: item.name }))"
              label="Регион"
              v-model="modalDevice.region_id"
              disabled
            />

            <v-text-field
              label="Название"
              required
              v-model="modalDevice.name"
            />

            <v-text-field
              label="Номер"
              required
              v-model="modalDevice.number"
            />

            <v-select
              :items="types.map(item => ({ value: item.id, text: item.name }))"
              label="Тип"
              v-model="modalDevice.type_id"
            />

            <Datepicker
              title="Дата поверки"
              v-model="modalDevice.verified_at"
            />

            <Datepicker
              title="Дата следующей поверки"
              v-model="modalDevice.next_verification"
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              text
              @click="onCancelClick"
            >
              Отмена
            </v-btn>
            <v-btn
              color="blue darken-1"
              outlined
              type="submit"
              :disabled="!modalDevice.region_id"
            >
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { ListsService, DevicesService } from "@/common/api.service"
import Datepicker from '@/components/Datepicker'

export default {
  components: {
    Datepicker
  },
  props: {
    device: {
      default: null
    }
  },
  data() {
    return {
      types: [],
      regions: [],
      modalDevice: {
        region_id: null,
        name: null,
        number: null,
        type_id: null,
        verified_at: null,
        next_verification: null
      },
      isOpened: false,
    }
  },
  methods: {
    onSubmit() {
      if( this.device.id ) {
        DevicesService.update(this.device.id, this.modalDevice).then(() => this.$emit('close', true))
      } else {
        DevicesService.add(this.modalDevice).then(() => this.$emit('close', true))
      }
    },

    onCancelClick() {
      this.$emit('close', false)
    }
  },
  watch: {
    device(value) {
      if (value) {
        this.isOpened = true
        for (const [key] of Object.entries(this.modalDevice)) {
          this.modalDevice[key] = this.device[key]
        }
      } else {
        this.isOpened = false
      }

      this.modalDevice.region_id = this.$store.state.auth.user.region_id
    }
  },
  beforeMount() {
    ListsService.get(['types', 'regions']).then(({data}) => {
      this.types = data.types
      this.regions = data.regions
    })
  }
}
</script>
